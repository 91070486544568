





























































import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import ComponentItemFormMixin from "./item-form.mixin";
import ResourceManger from "@/components/ResourceManger.vue";
import Editor from "@tinymce/tinymce-vue";
import tinymce from "tinymce";

// import "tinymce/skins/content/default/content.css";
import "tinymce/themes/silver/theme.min.js";
import "tinymce/icons/default/icons"; // 解决了icons.js 报错Unexpected token '<'

// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import "tinymce/plugins/image"; // 插入上传图片插件
import "tinymce/plugins/table"; // 插入表格插件
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/wordcount"; // 字数统计插件
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/paste";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/textpattern"; // 支持部分富文本功能

@Component({
  name: "Richtext",
  components: { Editor, ResourceManger },
  mixins: [],
})
export default class TinyMCEEdit extends Mixins(ComponentItemFormMixin) {
  public disable = false;
  private baseUrl = window.location.origin ? window.location.origin : "";
  public showResourceManger = false;
  public init = {
    language_url: `${this.baseUrl}/tinymce/lang/zh_CN.js`,
    skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
    language: "zh_CN",
    height: 620,
    toolbar:
      "bold italic underline lineheight strikethrough image table fullscreen searchreplace removeformat | fontsizeselect | formatselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent blockquote | undo redo | link unlink code lists media ",
    plugins:
      "link lists code table wordcount image paste fullscreen help media searchreplace textpattern",
    branding: false,
    statusbar: true,
    textpattern_patterns: [
      { start: "*", end: "*", format: "italic" },
      { start: "**", end: "**", format: "bold" },
      { start: "#", format: "h1" },
      { start: "##", format: "h2" },
      { start: "###", format: "h3" },
      { start: "####", format: "h4" },
      { start: "#####", format: "h5" },
      { start: "######", format: "h6" },
      { start: "1. ", cmd: "InsertOrderedList" },
      { start: "* ", cmd: "InsertUnorderedList" },
      { start: "- ", cmd: "InsertUnorderedList" },
    ],
    powerpaste_word_import: "clean",
  };

  emitHtmlData(value: any) {
    this.$emit("change", value);
  }

  mounted() {
    console.log(window);
    tinymce.init({});
  }
}
